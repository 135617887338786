body {
  position: fixed;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: Remove this once demo finished */

/* iframe
{
    display: none;
} */

/* inter-100 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-100.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-100.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-200 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-200.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-200.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-300 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-800 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-800.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-900 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local(''),
    url('./assets/fonts/inter-v12-latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/inter-v12-latin-900.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

#canvas-iframe {
  position: absolute;
  border: none;
}

.sd-root-modern {
  overflow: scroll auto;
}

.svc-page__add-new-question {
  width: 100%;
}

.svd_align_center {
  text-align: center;
}

.svd_align_right {
  text-align: right;
}

.svd_align_left {
  text-align: left;
}

.sv_question_title {
  font-size: 20px;
  font-weight: 600;
}

.sv_question_description {
  font-size: 13px;
  font-weight: 500;
  color: rgb(163, 163, 163);
  margin-top: -20px;
  margin-bottom: 20px;
}

.sv_rating_smiley_item {
  margin-left: 25px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50%;
  padding: 15px;
}

.sv_rating_smiley_item_preview {
  margin-left: 10px;
  margin-right: 2px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50%;
  padding: 20px;
}

.sv-row__question {
  box-sizing: border-box;
  width: 100%;
  word-break: break-word;
}
